import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';


export class EnvoiEmail extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { value: "1" }
        //this.handleChange = this.handleChange.bind(this);
    }

    // handleChange(event) {
    //     this.setState({ value: event.target.value });
    // }

    handleSubmit(event) {
        event.preventDefault();

        ////console.log("je suis dedans")
        // try {

        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });

        //     const reader = new FileReader();
        //     reader.readAsDataURL(event.target.files[0]);

        //     reader.onload = async (e) => {

        //         const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        //         const templateId = process.env.REACT_APP_EMAILJS_CERTIFICAT_INSCRIPTION_TEMPLATE_ID;

        // const emailParams = {
        //     from_name: "ISMI",
        //     to_name: `${this.props.prenom} ${this.props.nom}`,
        //     recipient: `${this.props.email}`,
        // };

        //         emailjs.send(serviceId, templateId, emailParams)
        //             .then((result) => {
        //                //console.log(result);
        //             }, (error) => {
        //                //console.log(error)
        //             })
        //     }
        //     alert("Email Votre e-mail a été envoyé successfully")
        // } catch (e) {
        //    //console.log(e)
        // }


        switch (this.state.value) {
            case "1":
                return emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_CERTIFICAT_INSCRIPTION_TEMPLATE_ID, event.target, this)
                    .then(() => {
                        alert('Votre e-mail a été envoyé avec Succès!');
                    }, (err) => {
                        alert(JSON.stringify(err));
                    });
            case "2":
                return emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_ATTESTATION_DE_SUIVI_TEMPLATE_ID, event.target, this)
                    .then(() => {
                        alert('Votre e-mail a été envoyé avec Succès!');
                    }, (err) => {
                        alert(JSON.stringify(err));
                    });;
            case "3":
                return emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_ATTESTATION_DE_FIN_DE_FORMLATION_TEMPLATE_ID, event.target, this)
                    .then(() => {
                        alert('Votre e-mail a été envoyé avec Succès!');
                    }, (err) => {
                        alert(JSON.stringify(err));
                    });;

        };
    }
    // try {
    //     emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID,
    //         process.env.REACT_APP_EMAILJS_CERTIFICAT_INSCRIPTION_TEMPLATE_ID, this, {
    //         from_name: "ISMI",
    //         to_name: `${this.props.prenom} ${this.props.nom}`,
    //         recipient: `${this.props.email}`
    //     });

    //    //console.log(this.state.value)
    //     
    // } catch (e) {
    //    //console.log(e)
    // }


    //     this.createNotification();
    // }





    render() {

        return (
            <div className="container">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Envoyer un email à un étudiant avec une pièce jointe
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Form onSubmit={this.handleSubmit} >
                                    <Form.Group controlId="ClientId">
                                        <Form.Label>ID de l'étudiant</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="clientId"
                                            required
                                            disabled
                                            defaultValue={this.props.clientid}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="Nom">
                                        <Form.Label>Nom de l'étudiant</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Nom"
                                            required
                                            defaultValue={this.props.nom}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="Prénom">
                                        <Form.Label>Prénom de l'étudiant</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="to_name"
                                            required
                                            defaultValue={this.props.prenom}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="Email">
                                        <Form.Label>Email de l'étudiant</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="recipient"
                                            required
                                            defaultValue={this.props.email}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="Numéro">
                                        <Form.Label>Numéro de téléphone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Numéro"
                                            required
                                            disabled
                                            defaultValue={this.props.numero}
                                        />

                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Type de document à envoyer</Form.Label>

                                        <Form.Select aria-label="Default select example" onChange={e => this.setState({ value: e.target.value })}>
                                            <option value="1">Certificat d'inscription</option>
                                            <option value="2">Attestation de suivi de formation</option>
                                            <option value="3">Attestation de suivi et de fin de formation</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group>

                                        <Form.Label>Fichier à envoyer</Form.Label>
                                        <Form.Control name="file" type="File" />
                                    </Form.Group>

                                    <Form.Group>
                                        <Button variant="primary" type="submit">
                                            Envoyer
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onHide}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}
