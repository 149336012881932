import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { CertificatDInscription } from '../PDFs/CertificatDInscription';
import ReactPDF from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

import signature from "../../images/SignatureISMI.png"
import banniere from "../../images/PictureISMI.png"

import ListGroup from 'react-bootstrap/ListGroup';




const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "13px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "50px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});

export class ProfilCandidat extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuppression = this.handleSuppression.bind(this);
        this.state = { value: "Brevet de technicien supérieur option Communication" };
    }



    handleSubmit(event) {
        event.preventDefault();

        axios.put(process.env.REACT_APP_API_URL + this.props.clientid, {
            inscrit: true,
            nomDeLaFormation: this.state.value,
            dateDebutDeFormation: event.target.dateDebutDeFormation.value,
            dateFinDeFormation: event.target.dateFinDeFormation.value
        }).then(response => response.data)

            .catch(error => console.error(error));
        window.location.href = '/dashboard';
    }

    // deletePost() {
    //     if (window.confirm('Êtes vous sûr de vouloir supprimer ce candidat ?')) {
    //         axios.delete(process.env.REACT_APP_API_URL + this.props.clientid
    //             //     {
    //             //     headers: {

    //             //         'Content-Type': 'application/json',
    //             //         'Access-Control-Allow-Origin': '*',
    //             //         'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET',
    //             //         authorization: "Bearer " + localStorage.getItem("accessToken")
    //             //     }
    //             // }
    //         )
    //             .then(response => {
    //                 alert("Le candidat a bien été supprimé")
    //                 window.location.href = "/dashboard"
    //             })
    //     }
    // }

    handleSuppression() {
        if (window.confirm('Voulez vous supprimer ce profil ?')) {
            axios.delete(process.env.REACT_APP_API_URL + this.props.clientid
                //     {
                //     headers: {

                //         'Content-Type': 'application/json',
                //         'Access-Control-Allow-Origin': '*',
                //         'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET',
                //         authorization: "Bearer " + localStorage.getItem("accessToken")
                //     }
                // }
            )
                .then(response => {
                    alert("Le profil a bien été supprimé de la base de données !")
                    window.location.href = "/dashboard"
                })
        }
    }

    handleRefus() {
        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });
        try {

            emailjs.send("service_2dzzalg", "template_04m047s", {
                from_name: "ISMI",
                to_name: `${this.props.prenom} ${this.props.nom}`,
                recipient: `${this.props.email}`
            });
            alert("Votre email de refus de candidature a bien été envoyé !")

        }
        catch (e) {
            //console.log(e)
        }

        if (window.confirm('Voulez vous supprimer ce candidat ?')) {
            axios.delete(process.env.REACT_APP_API_URL + this.props.clientid
                //     {
                //     headers: {

                //         'Content-Type': 'application/json',
                //         'Access-Control-Allow-Origin': '*',
                //         'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET',
                //         authorization: "Bearer " + localStorage.getItem("accessToken")
                //     }
                // }
            )
                .then(response => {
                    alert("Le candidat a bien été supprimé de la base de données !")
                    window.location.href = "/dashboard"
                })
        }
    }


    render() {

        return (
            <div className="container">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Valider une candidature
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={this.handleSubmit}>
                                    {this.props ?

                                        (
                                            <div>

                                                <div>

                                                    <Form.Group controlId="ClientId">
                                                        <Form.Label>ID du candidat</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="ClientId"
                                                            required
                                                            disabled
                                                            defaultValue={this.props.clientid}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group controlId="Nom">
                                                        <Form.Label>Nom</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="nom"
                                                            required
                                                            disabled
                                                            defaultValue={this.props.nom}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group controlId="Prenom">
                                                        <Form.Label>Prénom</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="prenom"
                                                            required
                                                            disabled
                                                            defaultValue={this.props.prenom}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group controlId="Email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="email"
                                                            required
                                                            defaultValue={this.props.email}
                                                        />

                                                    </Form.Group>
                                                    <Form.Group controlId="Numero">
                                                        <Form.Label>Numéro de téléphone</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="numero"
                                                            required
                                                            disabled

                                                            defaultValue={"+" + this.props.numero}
                                                        />

                                                    </Form.Group>
                                                </div>



                                                <Form.Group controlId="Questionnaire">
                                                    <h2>Identification besoin candidat</h2>


                                                    <div>

                                                        {/* //{this.props.checkboxesQuestionnaire.map((item, index) => <ul key={index}>{item}</ul>)} */}
                                                        <h3>1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3>
                                                        <p>Niveau de formation souhaité: {this.props.unun}</p><br /><br />
                                                        <p >Domaine de formation: {this.props.undeux}</p><br />
                                                        <p>Mode de formation souhaité: {this.props.untrois}</p>
                                                        <h3>2. Quel est votre parcours académique et professionnel ?</h3>
                                                        <p>Dernier diplôme: {this.props.deuxun}</p>
                                                        <p>Spécialité dernier diplôme: {this.props.specialitediplome}</p>
                                                        <p>Capable de présenter diplôme: {this.props.deuxtrois}</p>
                                                        <p>Non, pourquoi: {this.props.nonpresenterdiplomepourquoi}</p>
                                                        <p>Années expérience profesionnelle: {this.props.deuxquatre}</p>
                                                        <h3>3. Quel est votre objectif en suivant cette formation?</h3>
                                                        <p >Pourquoi cette formation:    {this.props.troisun}</p>
                                                        <p>Autre objectif de formation: {this.props.autreobjectif}</p>
                                                        <h3>4. Quelles sont vos attentes et motivations pour cette formation?</h3>
                                                        <p>Attente de la formation: {this.props.attentesrtmotivations}</p>
                                                        <p>Motivation: {this.props.motivation} </p>
                                                        <p>Depuis combien de temps intéressé par la formation: {this.props.depuiscombiendetempsinteressesparlaformation} </p>
                                                        <p >Combien de temps à consacrer: {this.props.quatred}</p>

                                                        <h3>4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3>
                                                        <p>Coordonnées référent alternance: {this.props.coordonneesreferentalternance} </p>
                                                        <p>Avez-vous déjà une entreprise pour alternance: {this.props.quatrebisa} </p>
                                                        <p>{<p >Poste correspond-t-il a votre formation: {this.props.quatrebisc}</p>}</p>
                                                        <p>Qualité être un bon profesionnel: {this.props.qualitespouretreunbonprofessionnel} </p>
                                                        <h3>5. Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap: </h3>
                                                        <p>
                                                            Réponse: {this.props.cinqa}
                                                        </p>
                                                        <p>Lesquels: {this.props.besoinsspecifiqueshandicap}</p>
                                                        <h3>6. Observations complémentaires</h3>
                                                        <p>{<p>Compréhension formation demande 600 à 1100h: {this.props.sixa}</p>}</p>
                                                        <p>{<p >Supports numériques: {this.props.sixb}</p>}</p>
                                                        <p>Besoins pour la création d'activité: {this.props.besoinscreationactivite}</p>
                                                        <p>Informations complémentaires: {this.props.autresinformationscomplementaires}</p>
                                                    </div>
                                                    {/* {console.log(this.props.checkboxesQuestionnaire.filter(item => item.includes("1.1 ")).map(filtered => <li>{filtered}</li>))} */}




                                                </Form.Group>



                                                <Form.Group controlId="Documents">
                                                    <h2>Documents à télécharger</h2>
                                                    {/* <Form.Control
type="text"
name="ImageFileName"
required
defaultValue={this.props.postimagefilename}
/> */}
                                                    <div style={{ margin: "20px" }}>


                                                        <ol>
                                                            <li>

                                                                <a href={process.env.REACT_APP_API_URL + this.props.cvMedia}>CV</a><br />
                                                            </li>
                                                            <li>

                                                                <a href={process.env.REACT_APP_API_URL + this.props.diplomeMedia}>Diplôme</a><br />
                                                            </li>
                                                            <li>

                                                                <a href={process.env.REACT_APP_API_URL + this.props.lettreDeMotivationMedia}>Lettre de motivation</a><br />
                                                            </li>
                                                            <li>

                                                                <a href={process.env.REACT_APP_API_URL + this.props.cniMedia}>Pièce d'identié</a><br />
                                                            </li>
                                                            {/* {process.env.REACT_APP_API_URL + this.props.cerfaMedia !== "" ?
                                                                <li>

                                                                    <a href={process.env.REACT_APP_API_URL + this.props.cerfaMedia}>Cerfa signé pour alternance</a><br />
                                                                </li>
                                                                : ""}

                                                            {process.env.REACT_APP_API_URL + this.props.ficheDePosteMedia !== "" ?
                                                                <li>

                                                                    <a href={process.env.REACT_APP_API_URL + this.props.ficheDePosteMedia}>Fiche de poste Alternance</a><br />
                                                                </li>
                                                                : ""} */}

                                                            <li>
                                                                <a>Signature</a>
                                                                <iframe src={this.props.signatureUrl} style={{ border: 0, top: "0px", left: "0px", bottom: "0px", right: "0px", width: "100%", height: "100%" }} ></iframe><br />
                                                            </li>

                                                        </ol>
                                                    </div>


                                                </Form.Group>
                                            </div>
                                        ) : ""}
                                    <Form.Group>
                                        <Form.Label>Intitulé de la formation</Form.Label>

                                        <Form.Select aria-label="Default select example" onChange={e => this.setState({ value: e.target.value })}>
                                            <option value="Brevet de technicien supérieur spécialité Communication en 1 an">BTS Communication en 1 an</option>
                                            <option value="Brevet de technicien supérieur spécialité Communication - Bac +1">BTS Communication - Bac +1</option>
                                            <option value="Brevet de technicien supérieur spécialité Communication - Bac +2">BTS Communication - Bac +2</option>
                                            <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée en 1 an">BTS Gestion du Transport et de la Logistique Associée en 1 an</option>
                                            <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée - Bac +1">BTS Gestion du Transport et de la Logistique Associée - Bac +1</option>
                                            <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée - Bac +2">BTS Gestion du Transport et de la Logistique Associée - Bac +2</option>
                                            <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel en 1 an">BTS Management Commercial Opérationnel en 1 an</option>
                                            <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel - Bac +1">BTS Management Commercial Opérationnel - Bac +1</option>
                                            <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel - Bac +2">BTS Management Commercial Opérationnel - Bac +2</option>
                                            <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC en 1 an">BTS Négociation et Digitalisation de la RC en 1 an</option>
                                            <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC - Bac +1">BTS Négociation et Digitalisation de la RC - Bac +1</option>
                                            <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC - Bac +2">BTS Négociation et Digitalisation de la RC - Bac +2</option>
                                            <option value="Brevet de technicien supérieur Services Informatiques option A:SISR en 1 an">BTS Services Informatiques A:SISR en 1 an</option>
                                            <option value="Brevet de technicien supérieur Services Informatiques option A:SISR - Bac +1">BTS Services Informatiques A:SISR - Bac +1</option>
                                            <option value="Brevet de technicien supérieur Services Informatiques option A:SISR - Bac +2">BTS Services Informatiques A:SISR - Bac +2</option>
                                            <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) en 1 an">BTS Services Informatiques B SLAM en 1 an</option>
                                            <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) - Bac +1">BTS Services Informatiques B SLAM - Bac +1</option>
                                            <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) - Bac +2">BTS Services Informatiques B SLAM - Bac +2</option>
                                            <option value="Bac +3: Manager Commercial Opérationnel en 1 an">Bac +3: Manager Commercial Opérationnel en 1 an</option>
                                            <option value="Bac +3: Manager Commercial Opérationnel - Bac +1">Bac +3: Manager Commercial Opérationnel - Bac +1</option>
                                            <option value="Bac +3: Manager Commercial Opérationnel - Bac +2">Bac +3: Manager Commercial Opérationnel - Bac +2</option>
                                            <option value="Bac +3: Manager Commercial Opérationnel - Bac +3">Bac +3: Manager Commercial Opérationnel - Bac +3</option>
                                            <option value="Mastère de Management des Organisations en 1 an">Mastère de Management des Organisations en 1 an</option>
                                            <option value="Mastère de Management des Organisations - Bac +4">Mastère de Management des Organisations - Bac +4</option>
                                            <option value="Mastère de Management des Organisations - Bac +5">Mastère de Management des Organisations - Bac +5</option>
                                            <option value="Mastère de Management de Business Unit en 1 an">Mastère de Management de Business Unit (MBU) en 1 an</option>
                                            <option value="Mastère de Management de Business Unit - Bac +4">Mastère de Management de Business Unit (MBU) - Bac +4</option>
                                            <option value="Mastère de Management de Business Unit - Bac +5">Mastère de Management de Business Unit (MBU)- Bac +5</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="DateDebutDeFormation">
                                        <Form.Label>Date de début de formation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="dateDebutDeFormation"

                                            required
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="DateFinDeFormation">
                                        <Form.Label>Date de fin de formation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="dateFinDeFormation"
                                            required
                                        />

                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Button variant="primary" onClick={this.handleSave}>
                                            Enregistrer

                                        </Button>
                                        <NotificationContainer />
                                    </Form.Group> */}
                                    <Form.Group>
                                        <h1>Merci de vérifier le paiement des frais de formation par le candidat, avant de valider son inscription.</h1>
                                    </Form.Group>

                                    <Form.Group>
                                        <Button variant="primary" type="submit">
                                            Valider la candidature

                                        </Button>
                                        <NotificationContainer />
                                    </Form.Group>
                                    <Form.Group>
                                        <Button variant="success" onClick={this.handleSuppression}>
                                            Supprimer le profil

                                        </Button>
                                    </Form.Group>
                                    <Form.Group>
                                        <Button variant="danger" onClick={() => this.handleRefus()}>
                                            Refuser la candidature

                                        </Button>
                                    </Form.Group>

                                    {/* <Form.Group>

                                        <Button
                                            className="mr-2"
                                            variant="danger"
                                            onClick={() =>
                                                this.deletePost()
                                            }>Supprimer le candidat
                                        </Button>
                                    </Form.Group> */}

                                </Form>
                            </Col >
                        </Row >
                    </Modal.Body > <Modal.Footer style={{ marginTop: "30px" }}>
                        <Button variant="danger" onClick={this.props.onHide}>Fermer</Button>
                    </Modal.Footer>
                </Modal >
            </div >

        );
    }
}
