import React, { image } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import banniere from "../../images/PictureISMI.png"
import signature from "../../images/SignatureISMI.png"
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Font } from '@react-pdf/renderer';
import myCustomFont from './fonts/calibrib.ttf'

// Create styles

Font.register({
    family: 'calibrib',
    src: myCustomFont
})
// Create styles
const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        paddingTop: "60",
        // paddingBottom: 65,
        paddingLeft: "60",
        paddingRight: "60"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 10,
        textAlign: "center",
        fontSize: "12pt"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10pt",
        //spaddingBottom: "40px",
        marginTop: "-10px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center",
        width: "50%",
        height: "30%"
    },
    h1: {
        color: "black",
        fontStyle: "normal",
        fontWeight: "bold",
        textDecoration: "underline",
        fontSize: "14pt",
        margin: "10"
    },
    h2: {
        color: "black",
        fontStyle: "normal",
        fontWeight: "bold",
        textDecoration: "none",
        fontSize: "12pt",
    }
});

dayjs.extend(relativeTime);

// Create Document Component
const date = new Date();
const year = date.getFullYear()
export const AttestationDeSuiviEtDeFormation = (props) => (


    <Document>
        <Page size="A4"
            style={styles.page}
        >
            <View>

                <Image
                    //style={styles.image} 
                    src={banniere}
                />
            </View>
            <View style={styles.left}>

                <Text

                >N° d’enregistrement auprès du préfet IDF :
                </Text><br />
                <Text >

                    11 75 44 681 75
                </Text>
            </View>
            <View >

                <Text
                    style={styles.right}
                >


                    Paris,</Text>
                <Text style={styles.right} >
                    Le : {new Intl.DateTimeFormat('fr-fr').format(Date.now())},

                </Text><br />
            </View>
            <View style={{ margin: "50px" }}>

                <Text
                    style={{ ...styles.h1, textAlign: "center", color: "red", fontSize: "14px" }}
                >ATTESTATION DE SUIVI DE FORMATION</Text><br />
            </View>
            <View >
                <Text
                    style={styles.text}
                >L’Institut de Stratégie et de Management International, représenté par Madame Audrey <br />
                    DUONG, responsable des formations, atteste, par la présente, que :
                </Text><br />
                <Text
                    style={{
                        ...styles.text, color: "black",
                        fontFamily: 'calibrib',
                        //fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "13pt"
                    }}
                >Mr/Mme {props.prenom} {props.nom}</Text><br />
                <Text
                    style={styles.text}
                >suit l’équivalent de 1 100h de formation professionnelle continue en vue d’obtenir le diplôme</Text> <br />
                <Text style={styles.text}>certifié par l’Etat et enregistré au RNCP de {props.nomDeLaFormation}</Text> <br />
                <Text style={styles.text}>La formation se déroule sur l’année académique {new Intl.DateTimeFormat('fr-FR', { year: 'numeric' }).format(date)} - {year + 1}</Text><br />
            </View>
            <View >
                <Text
                    style={{ ...styles.right, marginTop: "20px" }}
                >Pour faire valoir ce que de droit,</Text><br />
                <Text style={{ textIndent: "0pt", textAlign: "left" }}>

                </Text><br />

            </View>
            <View style={{ marginLeft: "130px", width: "40%", height: "20%", marginTop: "20px" }}><Image src={signature} /></View><br />
            <View>
                <Text
                    style={{ ...styles.right, marginTop: '-100px' }}
                >
                    Madame Audrey DUONG <br />
                </Text> <br />
                <Text style={styles.right}>
                    Directrice.
                </Text>
            </View>
            <View
                style={styles.footer}
            >
                <Text>
                    © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                <Text>
                    Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                </Text><br />
                <Text>
                    Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                </Text><br />
                <Text>
                    Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                </Text><br />
                <Text>
                    Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                </Text><br />
                <Text>
                    Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                </Text>

            </View>
        </Page>
    </Document >
);