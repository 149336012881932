
import './App.css';
//import StripeCheckout from 'react-stripe-checkout';
import { useState } from 'react';
//import Payment from '../../client/src/pages/Payment'
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Home } from '../src/pages/Home';
import { Dashboard } from '../src/pages/Dashboard';
import { Utilisateurs } from '../src/pages/Utilisateurs';
import Completion from '../src/pages/Completion';
import { EtudiantsInscrits } from './pages/EtudiantsInscrits';


import { LANGUAGES } from "../src/constants/index";

import { useTranslation } from 'react-i18next'
import { Login } from './pages/Login';
import { PageDePaiementFraisDeFormation } from './pages/PageDePaiementFraisDeFormation';
import { PolitiqueDeConfidentialite } from './pages/PolitiqueDeConfidentialite';
import { ConditionsGeneralesDUtilisation } from './pages/ConditionsGeneralesDUtilisation';


//const KEY = process.env.REACT_APP_STRIPE;

function App() {
  // const [stripeToken, setStripeToken] = useState(null);

  // const onToken = (token) => {
  //   setStripeToken(token);
  // }
  ////console.log(stripeToken);
  const { i18n, t } = useTranslation();

  //const navigate = useNavigate()

  const isLoggedIn = window.localStorage.getItem("accessToken")
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route index element={<Home />} />
          {/* <Route path="Payment" element={<Payment />} /> */}
          <Route path="/Dashboard" element={isLoggedIn ? <Dashboard /> : <Login />} />
          <Route path="/Inscrits" element={isLoggedIn ? <EtudiantsInscrits /> : <Login />} />
          {/* <Route path="/Utilisateurs" element={isLoggedIn ? <Utilisateurs /> : <Login />} /> */}
          <Route path="/PolitiqueDeConfidentialite" element={<PolitiqueDeConfidentialite />} />
          <Route path="/ConditionsGeneralesDUtilisation" element={<ConditionsGeneralesDUtilisation />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/PageDePaiementFraisDeFormation" element={<PageDePaiementFraisDeFormation />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </BrowserRouter>

    </div >
  );
}

export default App;
