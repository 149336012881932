
import React, { Component } from 'react';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import { Navigation } from '../components/Navigation';
import { AddUser } from '../components/Popups/AddUser';
import { EditUser } from '../components/Popups/EditUser';
import axios from 'axios';
import { NavBar } from '../components/NavBar';
export class Utilisateurs extends Component {
    constructor(props) {
        super(props);
        this.state = { user: [], addModalShow: false, editModalShow: false };
    }


    refreshList() {


        fetch(process.env.REACT_APP_API_URL + 'users', {
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET',
                authorization: "Bearer " + localStorage.getItem("accessToken")

            }
        })
            .then(response => {
                //console.log(response);
                if (response.status === 200) {
                    return response.json()
                }
                //console.log(response.status);
                throw response;
            })
            .then(data => {
                //console.log(data);
                this.setState({ user: data });
            })
            .catch(error => {
                console.error(error.message)
            });
    }

    componentDidMount() {
        this.refreshList();
    }

    componentDidUpdate() {
        this.refreshList();
    }

    deletePost(postid) {
        if (window.confirm('Êtes vous sûr de vouloir supprimer ?')) {
            fetch(process.env.REACT_APP_API + 'posts/' + postid, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(result => {
                    alert(result);
                })
        }
    }


    render() {
        const { user, userId, username, password } = this.state;
        let addModalClose = () => this.setState({ addModalShow: false });
        let editModalClose = () => this.setState({ editModalShow: false });

        return (
            <div>
                <nav id="nav-wrap">



                    <NavBar />
                </nav>  <div style={{ marginTop: 60, justifyContent: 'center' }}>

                    <h3>Gestion des utilisateurs</h3>
                    <div className="mt-5 d-flex justify-content-right">
                        <ButtonToolbar>
                            <Button
                                variant='primary'
                                onClick={() => this.setState({ addModalShow: true })}>
                                Ajouter un utilisateur
                            </Button>
                            <AddUser show={this.state.addModalShow}
                                onHide={addModalClose} />
                        </ButtonToolbar>
                    </div>
                    <div className="mt-5 d-flex justify-content-left">
                        <Table className="mt-4" striped bordered hover size="sm">
                            <thead>
                                <tr className='m-2'>
                                    <th>Utilisateur Id</th>
                                    <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.map(user =>
                                    <tr key={user._id}>
                                        <td>{user._id}</td>
                                        <td>{user.username}</td>
                                        <td>
                                            <ButtonToolbar>
                                                <Button
                                                    className="mr-2"
                                                    variant="info"
                                                    onClick={() => this.setState({
                                                        editModalShow: true, userId: user._id,
                                                        username: user.username, password: user.password,
                                                    })}>Modifier
                                                </Button>

                                                <Button
                                                    className="mr-2"
                                                    variant="danger"
                                                    onClick={() =>
                                                        this.deletePost(user.userId)
                                                    }>Supprimer
                                                </Button>

                                                <EditUser
                                                    show={this.state.editModalShow}
                                                    onHide={editModalClose}
                                                    userId={userId}
                                                    username={username}
                                                    password={password}
                                                />
                                            </ButtonToolbar>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </Table>


                    </div>
                </div>
            </div>)
    }

}
