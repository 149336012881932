
import React, { Component } from 'react';
//import { AddPost } from '../popups/AddPost';
import { ProfilEtudiantInscrit } from '../components/Popups/ProfilEtudiantInscrit';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import axios from 'axios';
import { Navigation } from '../components/Navigation';
import { NavBar } from '../components/NavBar';
//import { Navigation } from '../components/Navigation';
import { EnvoiEmail } from '../components/Popups/EnvoiEmail';

export class EtudiantsInscrits extends Component {
    constructor(props) {
        super(props);
        this.state = { client: [], addModalShow: false, editModalShow: false, UtilisateurUsername: false };
    }


    refreshList() {


        fetch(process.env.REACT_APP_API_URL, {
            headers:

            {
                authorization: "Bearer " + localStorage.getItem("accessToken")

            }
        }
        )
            .then(response => {
                //console.log(response);
                if (response.status === 200) {
                    return response.json()
                }
                //console.log(response.status);
                throw response;
            })
            .then(data => {
                //console.log(data);
                this.setState({ client: data.items });
            })
            .catch(error => {
                console.error(error.message)
            });
    }

    componentDidMount() {
        this.refreshList();
    }

    componentDidUpdate() {
        this.refreshList();
    }



    deletePost(clientid) {
        if (window.confirm('Êtes vous sûr de vouloir supprimer ?')) {
            fetch(process.env.REACT_APP_API + clientid, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                }
            })
                .then(response => response.json())
                .then(result => {
                    alert(result);
                })
        }
    }



    render() {
        const { client, clientid,
            cniMedia, lettreDeMotivationMedia, cvMedia, diplomeMedia,
            checkboxesQuestionnaire, specialiteDiplome, nonPresenterDiplomePourquoi, autreObjectif
            , attenteDeLaFormation, motivation, depuisCombienDeTempsInteressesParLaFormation,
            qualitesPourEtreUnBonProfessionnel, coordonneesReferentAlternance,
            besoinsSpecifiquesHandicap, besoinsCreationActivite, autresInformationsComplementaires,
            signatureUrl, nom, prenom, email, adresse, complementAdresse, numero, codePostal, ville,
            villeDeNaissance, inscrit, nomDeLaFormation, dateFinDeFormation, dateDebutDeFormation,
            nationalite, dateDeNaissance } = this.state;
        let addModalClose = () => this.setState({ addModalShow: false });
        let editModalClose = () => this.setState({ editModalShow: false });

        return (
            <div>
                <nav id="nav-wrap">

                    <NavBar />
                    {/* <Navigation /> */}
                </nav>  <div style={{ marginTop: 60, justifyContent: 'center' }}>

                    <h3>Étudiants inscrits</h3>
                    <div className="mt-5 d-flex justify-content-right">
                        <ButtonToolbar>
                            {/* <Button
                                variant='primary'
                                onClick={() => this.setState({ addModalShow: true })}>
                                Valider les candidatures
                            </Button> */}
                            {/* <AddPost show={this.state.addModalShow}
                                onHide={addModalClose} /> */}
                        </ButtonToolbar>
                    </div>
                    <div className="mt-5 d-flex justify-content-left">
                        <Table className="mt-4" striped bordered hover size="sm">
                            <thead>
                                <tr className='m-2'>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Adresse Email</th>
                                    <th>N° de téléphone</th>
                                    <th>Créneau de disponibilité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {client.map(client => client.inscrit === true ?
                                    <tr >

                                        <td>{client.nom}</td>
                                        <td>{client.prenom}</td>
                                        <td>{client.email}</td>
                                        <td>{"+" + client.numero}</td>
                                        <td>{client.creneauHoraire}</td>
                                        <td>
                                            <ButtonToolbar>
                                                <Button
                                                    className="mr-2"
                                                    variant="info"
                                                    onClick={() => this.setState({
                                                        addModalShow: true, clientid: client._id, cvMedia: client.cvMedia, cniMedia: client.cniMedia, diplomeMedia: client.diplomeMedia,
                                                        lettreDeMotivationMedia: client.lettreDeMotivationMedia,
                                                        nom: client.nom, prenom: client.prenom, numero: client.numero, email: client.email, adresse: client.adresse, complementAdresse: client.complementAdresse, codePostal: client.codePostal, ville: client.ville, creneauHoraire: client.creneauHoraire,
                                                        checkboxesQuestionnaire: client.checkboxesQuestionnaire, nonPresenterDiplomePourquoi: client.nonPresenterDiplomePourquoi,
                                                        autreObjectif: client.autreObjectif, attenteDeLaFormation: client.attenteDeLaFormation,
                                                        motivation: client.motivation, depuisCombienDeTempsInteressesParLaFormation: client.depuisCombienDeTempsInteressesParLaFormation,
                                                        qualitesPourEtreUnBonProfessionnel: client.qualitesPourEtreUnBonProfessionnel
                                                        , besoinsSpecifiquesHandicap: client.besoinsSpecifiquesHandicap,
                                                        coordonneesReferentAlternance: client.coordonneesReferentAlternance,
                                                        besoinsCreationActivite: client.besoinsCreationActivite, autresInformationsComplementaires: client.autresInformationsComplementaires,
                                                        specialiteDiplome: client.specialiteDiplome, signatureUrl: client.signatureUrl,
                                                        inscrit: client.inscrit, nomDeLaFormation: client.nomDeLaFormation,
                                                        dateFinDeFormation: client.dateFinDeFormation, dateDebutDeFormation: client.dateDebutDeFormation,
                                                        dateDeNaissance: client.dateDeNaissance,
                                                        villeDeNaissance: client.villeDeNaissance, nationalite: client.nationalite
                                                    })}>Voir les infos
                                                </Button>

                                                <Button
                                                    className="mr-2"
                                                    variant="success"
                                                    onClick={() => this.setState({
                                                        editModalShow: true, clientid: client._id,
                                                        nom: client.nom, prenom: client.prenom,
                                                        email: client.email,
                                                        numero: client.numero

                                                    })}>Envoyer un email
                                                </Button>
                                                <EnvoiEmail
                                                    show={this.state.editModalShow}
                                                    onHide={editModalClose}
                                                    clientid={clientid}
                                                    nom={nom}
                                                    prenom={prenom}
                                                    email={email}
                                                    numero={numero}
                                                />

                                                {/* <Button
                                                    className="mr-2"
                                                    variant="danger"
                                                    onClick={() =>
                                                        axios.delete(process.env.REACT_APP_API_URL + client._id)
                                                        // .then(response => {
                                                        //     //response.json();
                                                        //    //console.log(response);
                                                        // }
                                                        //  )
                                                    }>Supprimer
                                                </Button> */}

                                                {/* <EditPost */}
                                                <ProfilEtudiantInscrit
                                                    show={this.state.addModalShow}
                                                    onHide={addModalClose}
                                                    clientid={clientid}
                                                    checkboxesQuestionnaire={checkboxesQuestionnaire}
                                                    specialiteDiplome={specialiteDiplome}
                                                    autreObjectif={autreObjectif}
                                                    motivation={motivation}
                                                    nonPresenterDiplomePourquoi={nonPresenterDiplomePourquoi}
                                                    attenteDeLaFormation={attenteDeLaFormation}
                                                    depuisCombienDeTempsInteressesParLaFormation={depuisCombienDeTempsInteressesParLaFormation}
                                                    qualitesPourEtreUnBonProfessionnel={qualitesPourEtreUnBonProfessionnel}
                                                    coordonneesReferentAlternance={coordonneesReferentAlternance}
                                                    besoinsSpecifiquesHandicap={besoinsSpecifiquesHandicap}
                                                    besoinsCreationActivite={besoinsCreationActivite}
                                                    autresInformationsComplementaires={autresInformationsComplementaires}
                                                    signatureUrl={signatureUrl}
                                                    cniMedia={cniMedia}
                                                    cvMedia={cvMedia}
                                                    diplomeMedia={diplomeMedia}
                                                    lettreDeMotivationMedia={lettreDeMotivationMedia}
                                                    nom={nom}
                                                    prenom={prenom}
                                                    adresse={adresse}
                                                    complementAdresse={complementAdresse}
                                                    numero={numero}
                                                    codePostal={codePostal}
                                                    ville={ville}
                                                    email={email}
                                                    inscrit={inscrit}
                                                    nomDeLaFormation={nomDeLaFormation}
                                                    dateDebutDeFormation={dateDebutDeFormation}
                                                    dateFinDeFormation={dateFinDeFormation}
                                                    nationalite={nationalite}
                                                    dateDeNaissance={dateDeNaissance}
                                                    villeDeNaissance={villeDeNaissance}
                                                />
                                            </ButtonToolbar>
                                        </td>
                                    </tr> : "")}
                            </tbody>
                        </Table>


                    </div>
                </div>
            </div >)
    }

}
