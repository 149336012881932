import React, { useState } from "react"
import { useNavigate, Navigate } from "react-router-dom";
import axios from "axios";

export const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [redirection, setRedirection] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const request = await axios.post(process.env.REACT_APP_API_URL + 'login', {
                username,
                password

            }).then(response => {
                //console.log(response.data.accessToken)
                // if (response.data.accessToken) {
                //     localStorage.setItem("user", JSON.stringify(response.data))
                // }
                //console.log(response.status)
                if (response.status === 200) {

                    window.localStorage.setItem("accessToken", response.data.accessToken)
                    window.localStorage.setItem("isAdmin", response.data.isAdmin)
                    //window.localStorage.setItem("loggedIn", true)
                    window.location.href = "/Dashboard";
                    // setRedirection(true);
                    //console.log(response.data.isAdmin)

                } else {
                    alert(response.message);
                }
            }).catch(e =>
                alert("Problème Connexion"));

            //const response = await request.json();

            //console.log(response.message);


        } catch (err) {
            //console.log(err)
        }
        if (redirection) {
            navigate("/Dashboard");
        }

    }

    return (
        <form onSubmit={handleSubmit}>

            <div data-mdb-input-init className="form-outline mb-4">
                <input id="form2Example1" className="form-control"
                    onChange={(e) => setUsername(e.target.value)} />
                <label className="form-label" >Utilisateur </label>
            </div>


            <div data-mdb-input-init className="form-outline mb-4">
                <input type="password" id="form2Example2" className="form-control"
                    onChange={(e) => setPassword(e.target.value)} />
                <label className="form-label" >Mot de passe</label>
            </div>




            <button type="submit" data-mdb-button-init data-mdb-ripple-init className="btn btn-primary btn-block mb-4">Se Connecter</button>


        </form>
    )
}