import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import emailjs from '@emailjs/browser';
import img from '../images/banniereISMI.png'


export const PageDePaiementFraisDeFormation = () => {

    const form = useRef();

    const [email, setEmail] = useState("");

    const handleSubmitADistance = (e) => {
        e.preventDefault();
        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });

        //console.log(email)
        const params = {
            recipient: `${email}`
        }
        try {
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, "template_oq05m45", params
            )
                .then(() => {
                    alert('Un e-mail vous a été envoyé, vérifiez votre boite mail!');
                }, (err) => {
                    alert(JSON.stringify(err.text));
                });
        } catch (e) {

            //console.log(e);
        }


    }

    const handleSubmitPresentiel = (e) => {
        e.preventDefault();

        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });
        //console.log(email)

        const params = {
            recipient: `${email}`
        }

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, "template_dfxi6pv", params)
            .then(() => {
                alert('Un e-mail vous a été envoyé, vérifiez votre boite mail!');
            }, (err) => {
                alert(JSON.stringify(err.text));
            });
    }
    return (
        <div>
            <div>
                <div className="card-body">
                    <img className="card-img-top" src={img} alt="Card image cap" />
                </div>
            </div>
            <div id="block" >

                <h2 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Réservez votre place en formation</h2><br />
                <p>Cher(e) candidat(e), <br />
                    L’ISMI, vous offre la possibilité de régler vos frais de formation en 4 fois. <br />
                    Attention: des frais de 50 euros par virement s’appliquent. Pour rappel : le paiement en 1
                    fois permet d’éviter ces frais.
                    Pour cela,merci de nous transmettre le justificatif de mise en place auprès de votre
                    établissement bancaire de 4 virements mensuels de :
                </p>
                <div>
                    <p>

                        - pour la formation à distance : 425 euros = (1500/4) + 50 <br />
                        - pour la formation en présentiel : 1 025 euros =(3 900/4) + 50.
                    </p>

                </div>
                <p>
                    Seuls les dossiers complets ayant l’intégralité des documents demandés (justificatif de mise
                    en place de virement(s) inclus) seront étudiés par l’institut.
                    L’ISMI se réserve le droit de sélectionner ses candidats.
                    N&#39;hésitez pas à nous contacter pour toute question ou assistance concernant ces modalités
                    de paiement.</p>

                <div >

                    <div style={{
                        display: "inline-block",
                        width: "45vh",
                        //height: "100px",
                        padding: "10px",
                        border: "1px solid blue",
                        margin: "10px"
                    }}>
                        <h3>À distance</h3>
                        <Accordion >
                            <Accordion.Item >

                                <Button onClick={() => { window.location.href = "https://marketies.wolfeo.me/reservation-ismi-465052" }}>Je réserve ma formation à distance </Button>
                                {/* <Accordion.Body>
                                    Merci de saisir votre adresse e-mail afin que nous vous envoyions les coordonnées bancaires pour le virement
                                    correspondant aux frais d'inscription.
                                    <form name='myForm' onSubmit={handleSubmitADistance}>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">E-mail</label>
                                            <div className="col-sm-10">
                                                <input
                                                    style={{ margin: "10px", width: "80%" }}
                                                    type="text"
                                                    name="recipient"
                                                    required
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="form-control" placeholder="johndoe@example.com" />
                                            </div>
                                        </div>
                                        <Button type="submit" style={{ backgroundColor: "#94B9D9", margin: "30px" }}>Envoyer</Button>
                                    </form>
                                </Accordion.Body> */}
                            </Accordion.Item>
                        </Accordion>
                        {/* <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Régler en 1 fois
                            </button>
                        </h2>

                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>Les frais de formation pour un an de formation à distance sont de 1500€.</strong>
                                It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div> */}
                        {/* <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <a href="https://buy.stripe.com/3cs2a9d696W06zeaEE">

                                <Button>Régler en une fois</Button>
                            </a>
                        </Accordion.Item>
                    </Accordion> */}
                        {/* <a href="https://buy.stripe.com/3cs2a9d696W06zeaEE">

                            <Button >Régler en une fois par carte bleu</Button>
                        </a> */}
                    </div>
                    <div style={{
                        display: "inline-block",
                        width: "45vh",

                        //height: "100px",
                        padding: "10px",
                        border: "1px solid blue",
                        margin: "10px"

                    }}>
                        <h3>En Présentiel</h3>
                        <Accordion >
                            <Accordion.Item >
                                <Button onClick={() => window.location.href = "https://marketies.wolfeo.me/reservation-ismi-465052-copy-copy"}>Je réserve ma formation en présentiel</Button>
                                {/* <Accordion.Body>
                                    Merci de saisir votre adresse e-mail afin que nous vous envoyions les coordonnées bancaires pour le virement
                                    correspondant aux frais d'inscription.
                                    <form name='myForm' onSubmit={(e) => handleSubmitPresentiel(e)}>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">E-mail</label>
                                            <div className="col-sm-10">
                                                <input
                                                    style={{ margin: "10px", width: "80%" }}
                                                    type="text"
                                                    name="email"
                                                    required
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="form-control" placeholder="johndoe@example.com" />
                                            </div>
                                        </div>
                                        <Button type="submit" style={{ backgroundColor: "#94B9D9", margin: "30px" }}>Envoyer</Button>
                                    </form>
                                </Accordion.Body> */}
                            </Accordion.Item>
                        </Accordion>
                        {/* <a href="https://buy.stripe.com/bIY3ed2rv9489LqcMN">

                            <Button>Régler en une fois par carte bleu</Button>
                        </a> */}
                    </div>
                </div >
            </div>
            <div style={{ marginTop: "50px", padding: "20px" }}>
                © Institut de Stratégie et de Management International - Mai 2024 <br />

                Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                <br />

                Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                <br />

                Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                <br />

                Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                <br />

                Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI

            </div>
        </div >

    )
}