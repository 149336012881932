import React, { image, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import PictureLogoISMI from "../../images/PictureLogoISMI.png"
import signature from "../../images/SignatureISMI.png"
import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import myCustomFont from './fonts/calibrib.ttf'

// Create styles

Font.register({
    family: 'calibrib',
    src: myCustomFont
})

const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        padding: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",

        textAlign: "center",
        fontSize: "11pt"
    },
    textBold: {
        textAlign: "justify",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "11pt"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "30%",
    },
    right: {
        marginTop: "20px",
        textAlign: "right",
        fontSize: "11px"

    },
    center: {

    },
    textCivilite: {
        color: "black",
        fontFamily: 'calibrib',
        //fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12pt",

        textAlign: "left",
        display: "flex",
        flexDirection: "row"
    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "10px",
        fontSize: "11px"

    },
    interlignes: {
        lineHeight: "0.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "9px",
        marginTop: "-27px"
    },
    // signature: {
    //     marginTop: "40px",
    //     marginBottom: "40px",
    //     alignItems: "center"
    // },
    inline: {
        display: "flex",
        flexDirection: "row",
        fontSize: "11px",
        paddingLeft: "60pt",
        textIndent: "-10pt",
    }
});







export const CertificatDInscription = (props) => {

    return (
        <Document>
            <Page size="A4"
                style={styles.page}
            >
                <View >
                    <Image
                        style={styles.image}
                        src={PictureLogoISMI}
                    />
                </View>
                <View style={styles.right}>

                    <Text style={{ color: "red", fontSize: "14px" }}>CERTIFICAT D'INSCRIPTION DÉFINITIVE</Text>
                </View>
                <View style={
                    styles.left}>
                    <Text>

                        N° d’enregistrement auprès du préfet IDF :</Text><br />
                    <Text style={{ paddingLeft: "50px" }}>
                        11 75 44 681 75
                    </Text>
                </View>
                <View><Text style={{
                    color: "black",
                    fontFamily: "Calibri" | "sansSerif",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    textDecoration: "none",
                    fontSize: "11pt",
                    margin: "0pt", paddingTop: "8pt", textIndent: "0pt", textAlign: "left"
                }}>
                </Text><br />
                </View>
                <View style={{ margin: "20px" }}>
                    {/* <Text style={{ paddingLeft: "168pt", textIndent: "0pt", textAlign: "left" }}>

                    </Text> */}
                    <Text style={{ paddingTop: "1pt", paddingleft: "74pt", textIndent: "0pt", textAlign: "center" }}>
                        {props.nomDeLaFormation}
                    </Text>
                </View>
                <View>
                    <Text style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "1pt", textIndent: "0pt", textAlign: "left"
                    }}>

                    </Text>
                    <br />
                    <Text style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "13px" }}>

                        {'          '}Je soussignée, Madame Audrey DUONG, Directrice de l’Institut de Stratégie et de Management International, établissement d’enseignement supérieur privé, certifie que :
                    </Text>

                </View>
                <View style={{ marginTop: "13px", ...styles.inline }}>
                    <Text style={styles.inline}>

                        Nom de l&#39;étudiant (e ) : </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '54px' }}>
                        {props.nom}
                    </Text>

                </View>
                <View style={styles.inline}>

                    <Text style={styles.inline}>

                        Prénom de l’étudiant (e ) :
                    </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '41px' }}>
                        {props.prenom}

                    </Text>
                </View>
                <View style={styles.inline}>
                    <Text style={styles.inline}>

                        Né(e) le : </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '120px' }}>
                        {props.dateDeNaissance}
                    </Text>
                </View>
                <View style={styles.inline}>

                    <Text style={styles.inline}>
                        De nationalité :
                    </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '94px' }}>
                        {props.nationalite}
                    </Text>
                </View>
                <Text style={{
                    color: "black",
                    fontFamily: "Calibri" | "sansSerif",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    textDecoration: "none",
                    fontSize: "11pt",
                    margin: "0pt", paddingTop: "11pt", textIndent: "0pt", textAlign: "left"
                }}>

                </Text><br />
                {/* <View style={{ paddingLeft: "53pt", textIndent: "0pt", lineHeight: "115%", textAlign: "justify" }}>

                </View> */}
                <View style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "13px" }}>

                    <Text className="p">

                        {'          '} A été accepté (e ) et est bien inscrit (e) au sein de notre institut pour la formation au {props.nomDeLaFormation}

                    </Text>
                    <Text className="h4">


                    </Text>
                    <Text className="p">
                        qui se déroule sous l&#39;autorité du Ministère de l’Education Nationale enregistré au RNCP. .

                    </Text>
                </View>
                <View style={{ ...styles.inline, marginTop: "13px" }}>
                    <Text style={styles.inline}>
                        Date de rentrée de formation :
                    </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '20px' }}>
                        {props.dateDebutDeFormation}
                    </Text>
                </View>
                <View style={styles.inline}>
                    <Text style={styles.inline}>
                        Date de fin de formation :
                    </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '43px' }}>
                        {props.dateFinDeFormation}
                    </Text>
                </View>
                <View style={styles.inline}>
                    <Text style={styles.inline}>
                        Durée de la formation :
                    </Text>
                    <Text style={{ ...styles.textCivilite, marginLeft: '55px' }}>
                        1100 heures
                    </Text>
                </View>
                <View style={styles.inline}>
                    <Text style={styles.inline}>
                        Montant versé non remboursable :
                    </Text>
                    <Text style={styles.textCivilite}>
                        375 euros
                    </Text>

                </View >
                <View>
                    {/* <Text style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "11pt", textIndent: "0pt", textAlign: "left"
                    }}>
                    </Text><br /> */}
                    <Text style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "13px" }}>

                        {'          '}Compte tenu des diverses contraintes administratives (attente de visa, ...), l&#39;étudiant(e) dispose d&#39;une dérogation de rentrée tardive de 60 jours maximum après la date de rentrée pour se présenter en formation.
                    </Text>
                    <br />


                </View>
                <View style={styles.right}>
                    <Text >
                        Fait pour valoir ce que de droit,</Text><br /> <Text > à Paris,
                            le {new Intl.DateTimeFormat('fr-fr').format(Date.now())}
                    </Text> <br />

                    <Text style={{ textIndent: "0pt", textAlign: "left" }}>

                    </Text><br />
                </View>
                <View style={{ marginLeft: "130px", width: "40%", height: "20%", marginTop: "13px" }}><Image src={signature} /></View>

                <View>
                    <Text style={{ ...styles.right, marginTop: '-100px' }} >
                        Audrey DUONG, Directrice
                    </Text>
                </View>
                <View
                    style={styles.footer}
                >
                    <Text>
                        © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                    <Text>
                        Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                    </Text><br />
                    <Text>
                        Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                    </Text><br />
                    <Text>
                        Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                    </Text><br />
                    <Text>
                        Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                    </Text><br />
                    <Text>
                        Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                    </Text>

                </View>
            </Page >
        </Document >
    )
}

