import React, { useEffect, useState, useRef } from 'react'
import img from '../images/banniereISMI.png'
import Payment from './Payment'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from "react-signature-canvas"
import axios from 'axios';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { AttestationDeSuiviEtDeFinDeFormation } from '../components/PDFs/AttestationDeSuiviEtDeFinDeFormation'
import { PopupWidget } from "react-calendly";
import { PopupButton } from "react-calendly";
import "../App.css"
import { InlineWidget } from "react-calendly";
import { Button } from 'react-bootstrap'
import Professionnalisation from '../images/Professionnalisation.pdf'
import Apprentissage from '../images/Apprentissage.pdf'
import emailjs from '@emailjs/browser';
import { Navigation } from '../components/Navigation'
import LoadingBar from 'react-top-loading-bar'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ClipLoader from "react-spinners/ClipLoader";

import 'react-notifications/lib/notifications.css';


export const Home = () => {

    const navigate = useNavigate();
    const [sign, setSign] = useState()
    const [url, setUrl] = useState();
    // const [items, setItems] = useState([]);
    // const [selected, setSelected] = useState([]);
    const [cvMedia, setCvMedia] = useState()
    const [diplomeMedia, setDiplomeMedia] = useState()
    const [lettreDeMotivationMedia, setLettreDeMotivationMedia] = useState()
    const [cniMedia, setCniMedia] = useState()
    const [error, setError] = useState(false)
    const [phone, setPhone] = useState('');
    const [ficheDePosteMedia, setFicheDePosteMedia] = useState()
    const [cerfaMedia, setCerfaMedia] = useState()
    const [niveauDeFormationSouhaite, setNiveauDeFormationSouhaite] = useState([])
    const [domaineDeFormationSouhaite, setDomaineDeFormationSouhaite] = useState([])
    const [modeDeFormationChoisi, setModeDeFormationChoisi] = useState([])
    const [dernierDiplome, setDernierDiplome] = useState("")
    const [presenterDiplome, setPresenterDiplome] = useState("")
    const [experienceProfessionnelle, setExperienceProfessionnelle] = useState("")
    const [pourquoiCetteFormation, setPourquoiCetteFormation] = useState([])
    const [heuresAConsacrer, setHeuresAConsacrer] = useState("")
    const [entreprisePourAlternance, setEntreprisePourAlternance] = useState("")
    const [posteCorrespondALaFormation, setPosteCorrespondALaFormation] = useState("")
    const [situationDeHandicap, setSituationDeHandicap] = useState('')
    const [conscienceTempsNecessaire, setConscienceTempsNecessaire] = useState("")
    const [supportNumerique, setSupportNumerique] = useState([])
    const [accompagnement, setAccompagnement] = useState("")
    const [sex, setSex] = useState('')
    const [loading, setIsLoading] = useState(false)

    const ref = useRef(null)

    const [progress, setProgress] = useState(0)

    // const handleNiveauDeFormationSouhaite = (event) => {
    //     const newNiveauSouhaite = { ...niveauDeFormationSouhaite }
    //     newNiveauSouhaite[event.target.name] = event.target.value;
    //     setNiveauDeFormationSouhaite(newNiveauSouhaite);
    //    //console.log(newNiveauSouhaite);
    // }

    // const handleSetDomaineDeFormationSouhaite = (event) => {
    //     const newDomaineDeFormation = { ...domaineDeFormationSouhaite }
    //     newDomaineDeFormation = event.target.value;
    //     setDomaineDeFormationSouhaite(newDomaineDeFormation);
    //    //console.log(newDomaineDeFormation);
    // }

    // const handleSetModeDeFormationChoisi = (event) => {
    //     const newModeDeFormationChoisi = { ...modeDeFormationChoisi }
    //     newModeDeFormationChoisi[event] = event.target.value;
    //     setModeDeFormationChoisi(newModeDeFormationChoisi);
    //    //console.log(newModeDeFormationChoisi);
    // }

    // const handleSetPourquoiCetteFormation = (event) => {
    //     const newRaisonDeFormation = { ...pourquoiCetteFormation }
    //     newRaisonDeFormation[event] = event.target.value;
    //     setPourquoiCetteFormation(newRaisonDeFormation);
    //    //console.log(newRaisonDeFormation);
    // }

    // const handleSetSupportNumerique = (event) => {
    //     const newSupportNumerique = { ...supportNumerique }
    //     newSupportNumerique[event] = event.target.value;
    //     setSupportNumerique(newSupportNumerique);
    //    //console.log(newSupportNumerique);
    // }
    // const handleChange = (e) => {
    //    //console.log(e.target.value);

    //     const value = e.target.value;
    //     // const activeData = document.getElementById("my-checkbox").checked;

    //     ////console.log(activeData)

    //     if (e.target.checked) {
    //         setSelected([...selected, value])
    //     } else {
    //         setSelected(selected.filter(item => item !== value))
    //     }

    //     ////console.log(activeData)
    // }
    ////console.log(selected)



    // const [coordonnees, setCoordonnees] = useState({
    //     nom: "",
    //     prenom: "",
    //     email: "",
    //     adresse: "",
    //     complementAdresse: "",
    //     codePostal: "",
    //     ville: "",
    //     creneauHoraire: ""
    // })

    function createNotification(type) {
        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info('Merci de patienter, nous traitons votre demande');
                    break;
                case 'success':
                    NotificationManager.success('Success message', 'Title here');
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
            }
        };
    };

    const [client, setClient] = useState({
        // coordonnees: [],
        nom: "",
        prenom: "",
        adresse: "",
        complementAdresse: "",
        codePostal: "",
        ville: "",
        numero: "",
        villeDeNaissance: "",
        specialiteDiplome: "",
        autreObjectif: "",
        nonPresenterDiplomePourquoi: "",
        fonctionExperienceProfessionelle: "",
        autreRaisonObjectifFormation: "",
        attentesEtMotivations: "",
        depuisCombienDeTempsInteressesParLaFormation: "",
        qualitesPourEtreUnBonProfessionnel: "",
        coordonneesReferentAlternance: "",
        besoinsSpecifiquesHandicap: "",
        besoinsCreationActivite: "",
        autresInformationsComplementaires: "",
        checkboxesQuestionnaire: [],
        motivation: "",
        // attenteDeLaFormation: "",
        depuisCombienDeTempsInteressesParLaFormation: "",
        signatureUrl: "",
        nationalite: "",
        dateDeNaissance: "",
        // documentsTelecharges: [],
        // cvMedia: "",
        // diplomeMedia: "",
        // lettreDeMotivationMedia: "",
        // cniMedia: ""
    })

    // const [documentsTelecharges, setDocumentsTelecharges] = useState({
    //     cvMedia: "",
    //     diplomeMedia: "",
    //     lettreDeMotivationMedia: "",
    //     cniMedia: ""
    // })

    const handleClear = async (e) => {
        e.preventDefault();
        //sign.Clear();
    }

    // const [newDocuments, setNEwDocuments] = useState({
    //     cvMedia: '',
    //     diplomeMedia: '',
    //     lettreDeMotivationMedia: '',
    //     cniMedia: ''
    // });

    const maximumCheckBox = 24;
    const minimumCheckBox = 14;

    function handleInput(event) {
        const newClient = { ...client }
        newClient[event.target.name] = event.target.value;
        setClient(newClient);
        //console.log(newClient);

        // if(event.target.value.length){

        // }

        ////console.log(event.target.value.length)
        // const newCoordonnees = { ...coordonnees }
        // newCoordonnees[event.target.name] = event.target.value;
        // setCoordonnees(newCoordonnees);
        ////console.log(newCoordonnees)

        ////console.log(event.target.files[0])
        // const newDocument = { ...documentsTelecharges }
        // newDocument[event.target.name] = event.target.value;
        // setDocumentsTelecharges(newDocument);
        ////console.log(newDocument);//
        // setFormData({ ...formData, [event.target.name]: event.target.value });
        ////console.log(formData);
    }

    //console.log(cvMedia);
    const filesUploaded = new FormData();
    async function handleSave(event) {
        event.preventDefault();


        const config = { headers: { 'Content-Type': 'multipart/form-data' } }



        filesUploaded.append('signatureUrl', url)
        filesUploaded.append('cvMedia', cvMedia)
        filesUploaded.append('diplomeMedia', diplomeMedia)
        filesUploaded.append('lettreDeMotivationMedia', lettreDeMotivationMedia)
        filesUploaded.append('cniMedia', cniMedia)
        filesUploaded.append('ficheDePosteMedia', ficheDePosteMedia)
        filesUploaded.append('cerfaMedia', cerfaMedia)
        filesUploaded.append('nonPresenterDiplomePourquoi', client.nonPresenterDiplomePourquoi)
        filesUploaded.append('fonctionExperienceProfessionelle', client.fonctionExperienceProfessionelle)
        filesUploaded.append('autreRaisonObjectifFormation', client.autreRaisonObjectifFormation)
        filesUploaded.append('attentesEtMotivations', client.attentesEtMotivations)
        filesUploaded.append('depuisCombienDeTempsInteressesParLaFormation', client.depuisCombienDeTempsInteressesParLaFormation)
        filesUploaded.append('qualitesPourEtreUnBonProfessionnel', client.qualitesPourEtreUnBonProfessionnel)
        filesUploaded.append('coordonneesReferentAlternance', client.coordonneesReferentAlternance)
        filesUploaded.append('numero', phone)
        filesUploaded.append('besoinsSpecifiquesHandicap', client.besoinsSpecifiquesHandicap)
        filesUploaded.append('besoinsCreationActivite', client.besoinsCreationActivite)
        filesUploaded.append('autresInformationsComplementaires', client.autresInformationsComplementaires)
        //filesUploaded.append('checkboxesQuestionnaire', selected)
        filesUploaded.append('motivation', client.motivation)
        //filesUploaded.append('attenteDeLaFormation', client.attenteDeLaFormation)

        // filesUploaded.append('nom', client.nom)
        // filesUploaded.append('prenom', client.prenom)
        // filesUploaded.append('adresse', client.adresse)
        // filesUploaded.append('complementAdresse', client.complementAdresse)
        // filesUploaded.append('codePostal', client.codePostal)
        // filesUploaded.append('ville', client.ville)
        // filesUploaded.append('creneauHoraire', client.creneauHoraire)

        //filesUploaded.append('coordonnees', coordonnees)
        filesUploaded.append('nom', client.nom)
        filesUploaded.append('prenom', client.prenom)
        filesUploaded.append('adresse', client.adresse)
        filesUploaded.append('ville', client.ville)
        filesUploaded.append('villeDeNaissance', client.villeDeNaissance)
        filesUploaded.append('complementAdresse', client.complementAdresse)
        filesUploaded.append('codePostal', client.codePostal)
        filesUploaded.append('email', client.email)
        filesUploaded.append('dateDeNaissance', client.dateDeNaissance)
        filesUploaded.append('nationalite', client.nationalite)
        filesUploaded.append('unUn', niveauDeFormationSouhaite)
        filesUploaded.append('unDeux', domaineDeFormationSouhaite)
        filesUploaded.append('unTrois', modeDeFormationChoisi)
        filesUploaded.append('deuxUn', dernierDiplome)
        filesUploaded.append('deuxTrois', presenterDiplome)
        filesUploaded.append('deuxQuatre', experienceProfessionnelle)
        filesUploaded.append('troisUn', pourquoiCetteFormation)
        filesUploaded.append('quatreD', heuresAConsacrer)
        filesUploaded.append('quatreBisA', entreprisePourAlternance)
        filesUploaded.append('quatreBisC', posteCorrespondALaFormation)
        filesUploaded.append('cinqA', situationDeHandicap)
        filesUploaded.append('sixA', conscienceTempsNecessaire)
        filesUploaded.append('sixB', supportNumerique)
        filesUploaded.append('sixC', accompagnement)
        filesUploaded.append('sex', sex)
        filesUploaded.append('specialiteDiplome', client.specialiteDiplome)



        setUrl(sign.getTrimmedCanvas().toDataURL('Image/png'));




        if (cniMedia && cvMedia &&
            lettreDeMotivationMedia &&
            diplomeMedia && url &&
            client.nom !== "" &&
            client.prenom !== "" &&
            client.adresse !== "" &&
            phone !== "" &&
            client.codePostal !== "" &&
            client.ville !== "" &&
            client.email !== "" &&
            client.dateDeNaissance !== "" &&
            client.nationalite !== "" && client.villeDeNaissance !== ""
        ) {

            try {
                setIsLoading(true)
                await axios.post(process.env.REACT_APP_API_URL, filesUploaded, config
                )

                setIsLoading(false)
                window.location.href = "/PageDePaiementFraisDeFormation"


            }

            catch (e) {

                setIsLoading(false)
                alert("La taille d'un fichier doit être inférieure à 2 Mo, merci de le compresser !")

                console.log(e)

            }


        }
        else if (cniMedia == "undefined" || cvMedia == "undefined" ||
            lettreDeMotivationMedia == "undefined" ||
            diplomeMedia == "undefined" || url === "undefined" ||
            client.nom === "" ||
            client.prenom === "" ||
            client.adresse === "" ||
            phone === "" ||
            client.codePostal === "" ||
            client.ville === "" ||
            client.email === "" ||
            client.dateDeNaissance === "" ||
            client.nationalite === "" || client.villeDeNaissance === "") {
            alert("Merci de renseigner tous les champs obligatoires !")
        }

    }




    return (
        <div className="App">
            {/* <div>
                <AttestationDeSuiviEtDeFinDeFormation />
            </div> */}

            <div><Navigation /></div>
            <div className='text-center'>
                <h1 id="header">Formulaire d'inscription</h1>
            </div>

            <div className="card-body">
                <img className="card-img-top" src={img} alt="Card image cap" />
            </div>
            <form name='myForm'>
                <div className="col-md-6 offset-md-3">
                    <h2>Votre état civil <span>*</span></h2>

                    <div style={{ margin: "20px" }}>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="Monsieur"
                                onChange={(e) => setSex(e.target.value)}
                                type="radio" id="customRadioInline14" name="customRadioInline14" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline14"> Homme</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="Madame"
                                onChange={(e) => setSex(e.target.value)}
                                type="radio" id="customRadioInline15" name="customRadioInline14" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline15"> Femme</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Prénom</label>
                            <div className="col-sm-10">
                                <input
                                    style={{ margin: "10px" }}
                                    type="text"
                                    name="prenom"
                                    required
                                    onChange={(e) => handleInput(e)}
                                    value={client.prenom}
                                    className="form-control" placeholder="Votre Prénom" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">NOM</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="nom"
                                    //value={nom}
                                    onChange={(e) => handleInput(e)}
                                    value={client.nom}
                                    required
                                    className="form-control" placeholder="Votre NOM" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    name="email"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.email}

                                    required
                                    className="form-control" placeholder="Votre Adresse E-Mail" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Numéro de Téléphone</label>
                            <div className="col-sm-10">

                                <PhoneInput
                                    defaultCountry="fr"
                                    value={phone}
                                    name="numero"
                                    required

                                    onChange={(phone) => setPhone(phone)}
                                />

                                {/* <input type="text"
                                    name="numero"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.numero}

                                    required
                                    className="form-control" placeholder="Votre N° de téléphone" /> */}
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Adresse</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="adresse"
                                    onChange={(e) => handleInput(e)}
                                    value={client.adresse}


                                    required
                                    className="form-control" placeholder="Votre Adresse" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Complément</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="complementAdresse"

                                    onChange={(e) => handleInput(e)}
                                    value={client.complementAdresse}
                                    className="form-control" placeholder="Complément d'adresse" />
                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Code Postal</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    name="codePostal"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.codePostal}
                                    required
                                    className="form-control" placeholder="Votre code postal" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Ville</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="ville"
                                    onChange={(e) => handleInput(e)}
                                    value={client.ville}
                                    required
                                    className="form-control" placeholder="Votre ville" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Date de Naissance</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="dateDeNaissance"
                                    onChange={(e) => handleInput(e)}
                                    value={client.dateDeNaissance}
                                    required
                                    className="form-control" placeholder="01/01/1970" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Ville de Naissance</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="villeDeNaissance"
                                    onChange={(e) => handleInput(e)}
                                    value={client.villeDeNaissance}
                                    required
                                    className="form-control" placeholder="Paris" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Nationalité</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="nationalite"
                                    onChange={(e) => handleInput(e)}
                                    value={client.nationalite}
                                    required
                                    className="form-control" placeholder="Française" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>


                    </div>
                </div>


                <div className='row' >
                    <div className="col-md-6 offset-md-3">
                        <div >

                            <h3 htmlFor="exampleFormControlSelect1">1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3><br />
                        </div>
                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginTop: 0 }} htmlFor="exampleFormControlSelect1">1.1. Quel est le niveau de formation souhaité? <span>*</span></h4><br />
                            <div className="custom-control custom-checkbox" >
                                <input type="checkbox"
                                    value="1.1 Bac +2"
                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1"> Bac +2</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +3"
                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}

                                    type="checkbox" className="custom-control-input" id="customCheck2" />
                                <label className="custom-control-label" htmlFor="customCheck2"> Bac +3</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +4"


                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck3" />
                                <label className="custom-control-label" htmlFor="customCheck3"> Bac +4</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +5"


                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck4" />
                                <label className="custom-control-label" htmlFor="customCheck4"> Bac +5</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input

                                    value="1.1 Bac +6"
                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck5" />
                                <label className="custom-control-label" htmlFor="customCheck5"> Bac +6</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input value="1.1 Bac +7"


                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck6" />
                                <label className="custom-control-label" htmlFor="customCheck6"> Bac +7</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +8"



                                    onChange={(e) => niveauDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck7" />
                                <label className="custom-control-label" htmlFor="customCheck7"> Bac +8</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">1.2. Quel domaine de formation souhaitez-vous?
                            </h4><br />
                            <div className="custom-control custom-checkbox" >
                                <input
                                    value="1.2 Assurance"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck8" />
                                <label className="custom-control-label" htmlFor="customCheck8"> Assurance</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Banque"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck9" />
                                <label className="custom-control-label" htmlFor="customCheck9"> Banque</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Commerce International - Import Export"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}


                                    type="checkbox" className="custom-control-input" id="customCheck10" />
                                <label className="custom-control-label" htmlFor="customCheck10"> Commerce International - Import Export</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Communication"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck11" />
                                <label className="custom-control-label" htmlFor="customCheck11"> Communication</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Comptabilité Gestion"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck12" />
                                <label className="custom-control-label" htmlFor="customCheck12"> Comptabilité Gestion</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Création d'entreprise"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck12" />
                                <label className="custom-control-label" htmlFor="customCheck12">Création d'entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Gestion de la PME"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck13" />
                                <label className="custom-control-label" htmlFor="customCheck13"> Gestion de la PME</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management de Business Unit"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck14" />
                                <label className="custom-control-label" htmlFor="customCheck14"> Management de Business Unit</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management de l'Hôtellerie et de la Restauration"


                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck14" />
                                <label className="custom-control-label" htmlFor="customCheck14"> Management de l'Hôtellerie et de la Restauration</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2  Management de Projets Informatiques"

                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck15" />
                                <label className="custom-control-label" htmlFor="customCheck15"> Management de Projets Informatiques</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2  Management Commercial Opérationnel"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck16" />
                                <label className="custom-control-label" htmlFor="customCheck16"> Management Commercial Opérationnel</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management des Ressources Humaines"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck17" />
                                <label className="custom-control-label" htmlFor="customCheck17"> Management des Ressources Humaines</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Négociation et Digitalisation de la Relation Client"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck18" />
                                <label className="custom-control-label" htmlFor="customCheck18"> Négociation et Digitalisation de la Relation Client</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Notariat"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck19" />
                                <label className="custom-control-label" htmlFor="customCheck19"> Notariat</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Professions Immobilières"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck20" />
                                <label className="custom-control-label" htmlFor="customCheck20"> Professions Immobilières</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Services Informatiques"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck21" />
                                <label className="custom-control-label" htmlFor="customCheck21"> Services Informatiques</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Services à la personne"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck22" />
                                <label className="custom-control-label" htmlFor="customCheck22"> Services à la personne</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Support à l'Action Managériale"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck23" />
                                <label className="custom-control-label" htmlFor="customCheck23"> Support à l'Action Managériale</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Transport Logistique"
                                    onChange={(e) => domaineDeFormationSouhaite.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck24" />
                                <label className="custom-control-label" htmlFor="customCheck24"> Transport Logistique</label>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">1.3. Quel mode de formation souhaitez-vous? <span>*</span></h4><br />
                            <div className="custom-control custom-checkbox" required>
                                <input
                                    value="1.3 A distance : Accès 24h/24 et 7j/7 à notre plateforme e learning ISMI Campus + 1 heure de suivi tutoré live hebdomadaire"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck25" />
                                <label className="custom-control-label" htmlFor="customCheck25"> A distance : Accès 24h/24 et 7j/7 à notre plateforme e learning ISMI Campus + 1 heure de suivi tutoré live hebdomadaire</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En alternance :  1 à 2 jours de cours par semaine + présence en entreprise"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck26" />
                                <label className="custom-control-label" htmlFor="customCheck26"> En alternance :  1 à 2 jours de cours par semaine + présence en entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En présentiel : au centre de formation et en entreprise"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck29" />
                                <label className="custom-control-label" htmlFor="customCheck29"> En présentiel : au centre de formation et en entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 Via la validation des acquis par l'expérience (VAE)"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck27" />
                                <label className="custom-control-label" htmlFor="customCheck27"> Via la validation des acquis par l'expérience (VAE)</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En formation sur mesure (150 euros TTC / heure - Bilan de compétences exigé)"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck28" />
                                <label className="custom-control-label" htmlFor="customCheck28"> En formation sur mesure (150 euros TTC / heure - Bilan de compétences exigé)</label>
                            </div>

                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 Autre :"
                                    onChange={(e) => modeDeFormationChoisi.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck30" />
                                <label className="custom-control-label" htmlFor="customCheck30"> Autre </label>
                                {/* <input type="text" 
                                onChange={(e) => handleInput(e)}
                                value={client.specialiteDiplome}
                                className="form-control" /> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <h3 htmlFor="exampleFormControlSelect1">2. Quel est votre parcours académique et professionnel ?
                        </h3><br />
                        <div id="block" required>

                            <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">2.1. Quel est votre parcours académique? Quel est votre dernier diplôme (RNCP ou Européen) obtenu?
                            </h4><br />

                            <div className="custom-control custom-checkbox" >
                                <input
                                    value="2.1 Bac + 5 ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck31" />
                                <label className="custom-control-label" htmlFor="customCheck31"> Bac + 5 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac + 4 ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck32" />
                                <label className="custom-control-label" htmlFor="customCheck32"> Bac + 4 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac + 3 ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck33" />
                                <label className="custom-control-label" htmlFor="customCheck33"> Bac + 3 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac +2 ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck34" />
                                <label className="custom-control-label" htmlFor="customCheck34"> Bac +2 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox" >
                                <input
                                    value="2.1 Bac +1 ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck35" />
                                <label className="custom-control-label" htmlFor="customCheck35"> Bac +1 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Baccalauréat ou équivalent"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck36" />
                                <label className="custom-control-label" htmlFor="customCheck36"> Baccalauréat ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Autre diplôme (étranger,...)"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck37" />
                                <label className="custom-control-label" htmlFor="customCheck37"> Autre diplôme (étranger,...)</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Pas de diplôme"
                                    onChange={(e) => setDernierDiplome(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck38" />
                                <label className="custom-control-label" htmlFor="customCheck38"> Pas de diplôme</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" required>

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">2.2. Dans quelle(s) spécialité(s), votre ou vos diplôme(s) a (ont) - il (s) été obtenu (s)?</h4><br />
                            <input type="text" name="specialiteDiplome"
                                onChange={(e) => handleInput(e)}
                                value={client.specialiteDiplome}
                                style={{ width: "100%", backgroundColor: "cararra" }} className="form-control" placeholder="Spécialité" />
                        </div>

                        <div id="block">

                            <h4 htmlFor="exampleFormControlSelect1">2.3. Etes - vous capable de présenter votre diplôme (ou tout justificatif équivalent : relevé de notes , attestation officielle,...) ou des justificatifs liés à votre expérience professionnelle pour valider votre  inscription à l'examen?</h4><br />

                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="2.3 Oui"
                                    onChange={(e) => setPresenterDiplome(e.target.value)}
                                    type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline1"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="2.3 Non"
                                    onChange={(e) => setPresenterDiplome(e.target.value)}
                                    type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline2"> Non</label>
                            </div>

                            <div>
                                <label htmlFor="exampleFormControlSelect1">Si non, pourquoi ?</label><br />
                                <input name="nonPresenterDiplomePourquoi"
                                    onChange={(e) => handleInput(e)}
                                    value={client.nonPresenterDiplomePourquoi}
                                    style={{ width: "100%", backgroundColor: "cararra" }} type="text" className="form-control" />
                            </div>
                        </div>

                        <div id="block" required>
                            <h4 htmlFor="exampleFormControlSelect1">2.4. Combien d'années d'expérience professionnelle pouvez-vous justifier ?
                            </h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.4 Plus de 5 ans"
                                    onChange={(e) => setExperienceProfessionnelle(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck39" />
                                <label className="custom-control-label" htmlFor="customCheck39"> Plus de 5 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.4 De 3 à 5 ans"
                                    onChange={(e) => setExperienceProfessionnelle(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck40" />
                                <label className="custom-control-label" htmlFor="customCheck40"> De de 3 à 5 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.4 Moins de 3 ans"
                                    onChange={(e) => setExperienceProfessionnelle(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck41" />
                                <label className="custom-control-label" htmlFor="customCheck41"> Moins de 3 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.4 Aucune expérience professionnelle"
                                    onChange={(e) => setExperienceProfessionnelle(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck42" />
                                <label className="custom-control-label" htmlFor="customCheck42"> Aucune expérience professionnelle</label>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 offset-md-3">

                        <div id="block">
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">2.5. Dans le cadre de votre expérience professionnelle, quelle(s) ont été vos fonction(s) et pendant combien d'années?</h4><br />
                            <input

                                name="fonctionExperienceProfessionelle"
                                onChange={(e) => handleInput(e)}
                                value={client.fonctionExperienceProfessionelle}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">3. Quel est votre objectif en suivant cette formation?</h3><br />
                        <div id="block" required >
                            <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">3. 1. Pourquoi avez-vous choisi de débuter cette formation ?</h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Acquérir des compétences"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck43" />
                                <label className="custom-control-label" htmlFor="customCheck43"> Acquérir des compétences</label>
                            </div>

                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Obtenir un diplôme"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck44" />
                                <label className="custom-control-label" htmlFor="customCheck44"> Obtenir un diplôme</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Poursuivre mes études"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck45" />
                                <label className="custom-control-label" htmlFor="customCheck45"> Poursuivre mes études</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Intégrer une grande école"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck46" />
                                <label className="custom-control-label" htmlFor="customCheck46"> Intégrer une grande école</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1  Effectuer un parcours à l'international"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck47" />
                                <label className="custom-control-label" htmlFor="customCheck47"> Effectuer un parcours à l'international</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Intégrer en fin de parcours une entreprise"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck48" />
                                <label className="custom-control-label" htmlFor="customCheck48"> Intégrer en fin de parcours une entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Autre Raison"
                                    onChange={(e) => pourquoiCetteFormation.push(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck49" />
                                <label className="custom-control-label" htmlFor="customCheck49"> Autre(s) raison(s)</label>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">3.2. Si autre raison, merci de préciser</h4><br />
                            <input name="autreRaisonObjectifFormation"
                                onChange={(e) => handleInput(e)}
                                value={client.autreRaisonObjectifFormation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">4. Quelles sont vos attentes et motivations pour cette formation?</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.a. Qu'attendez-vous de cette formation?</h4><br />
                            <input
                                required
                                name="attentesEtMotivations"
                                onChange={(e) => handleInput(e)}
                                value={client.attentesEtMotivations}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                        {error ?

                            <label className='error'>Ce champ est obligatoire</label>
                            : ""}
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.b. Quelles sont vos motivations pour cette formation ? </h4><br />
                            <input name="motivation"
                                onChange={(e) => handleInput(e)}
                                required
                                value={client.motivation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.c. Depuis combien de temps vous intéressez-vous à cette formation ?  </h4><br />
                            <input
                                name="depuisCombienDeTempsInteressesParLaFormation"
                                onChange={(e) => handleInput(e)}
                                required
                                value={client.depuisCombienDeTempsInteressesParLaFormation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" required >

                            <h4 htmlFor="exampleFormControlSelect1">4.d. Combien d'heures par jour pensez-vous pouvoir consacrer à votre travail personnel, en plus des heures de cours liées à la formation?<span>*</span></h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Moins d'1h par jour"
                                    onChange={(e) => setHeuresAConsacrer(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck50" />
                                <label className="custom-control-label" htmlFor="customCheck50"> Moins d'1h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus d'1h et moins de 2h par jour"
                                    onChange={(e) => setHeuresAConsacrer(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck51" />
                                <label className="custom-control-label" htmlFor="customCheck51"> 1h à 2h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus de 2h et moins de 3h par jour"
                                    onChange={(e) => setHeuresAConsacrer(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck52" />
                                <label className="custom-control-label" htmlFor="customCheck52"> Plus de 2h à 3h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus de 3h par jour"
                                    onChange={() => setHeuresAConsacrer("Plus de 3h par jour")}
                                    type="checkbox" className="custom-control-input" id="customCheck53" />
                                <label className="custom-control-label" htmlFor="customCheck53"> 3h et plus par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Le temps nécessaires pour obtenir mon diplôme"
                                    onChange={(e) => setHeuresAConsacrer(e.target.value)}
                                    type="checkbox" className="custom-control-input" id="customCheck54" />
                                <label className="custom-control-label" htmlFor="customCheck54"> Le temps nécessaire pour obtenir mon diplôme</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.e. D’après vous, quelles sont les qualités nécessaires pour être un bon professionnel ? </h4><br />
                            <input
                                name="qualitesPourEtreUnBonProfessionnel"
                                required
                                onChange={(e) => handleInput(e)}
                                value={client.qualitesPourEtreUnBonProfessionnel}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginTop: 0 }} htmlFor="exampleFormControlSelect1">4.bis. a. Avez-vous déjà une entreprise vous ayant proposé un contrat d'alternance?</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis Oui"
                                    onChange={(e) => setEntreprisePourAlternance(e.target.value)}
                                    type="radio" id="customRadioInline3" name="customRadioInline3" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline3"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis Non"
                                    onChange={(e) => setEntreprisePourAlternance(e.target.value)}
                                    type="radio" id="customRadioInline4" name="customRadioInline3" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline4"> Non</label>
                            </div>
                            <div>
                                <a href={Apprentissage} target="_blank">
                                    <Button>Télécharger Cerfa - Apprentissage</Button>
                                </a>
                            </div>
                            <div>
                                <a href={Professionnalisation} target="_blank">
                                    <Button>Télécharger Cerfa - Professionnalisation</Button>
                                </a>
                            </div>


                        </div>
                    </div>


                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.bis. b. Si oui, merci de préciser ci dessous les coordonnées (NOM, Prénom, Téléphone, mail) du contact en entreprise ?</h4><br />
                            <textarea
                                name="coordonneesReferentAlternance"
                                onChange={(e) => handleInput(e)}
                                value={client.coordonneesReferentAlternance}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                        <div>
                            <h4>Transmettre le Cerfa signé</h4>
                            <input type="file"
                                name="cerfaMedia"
                                required
                                onChange={(e) => setCerfaMedia(e.target.files[0])}
                                class="form-control" id="customFile" />

                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">4.bis. c. Avez-vous vérifié que le poste proposé par l'entreprise correspond bien à la formation que vous avez choisie? Dans tous les cas, merci de transmettre la fiche de poste proposée par l'entreprise pour validation par nos soins.</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis.c Oui"
                                    onChange={(e) => setPosteCorrespondALaFormation(e.target.value)}
                                    type="radio" id="customRadioInline5" name="customRadioInline5" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline5"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis.c Non"
                                    onChange={(e) => setPosteCorrespondALaFormation(e.target.value)}
                                    type="radio" id="customRadioInline6" name="customRadioInline5" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline6"> Non</label>
                            </div>
                            <div className="custom-file">
                                <h4 style={{ marginTop: 40, }} htmlFor="exampleFormControlSelect1">Fiche de poste</h4><br />

                                <input type="file"
                                    name="ficheDePosteMedia"
                                    required
                                    onChange={(e) => setFicheDePosteMedia(e.target.files[0])}
                                    // value={formData.cniMedia}
                                    class="form-control" id="customFile" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">5.Situation de Handicap</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">
                                5.a Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap?
                            </h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="5. Oui"
                                    onChange={(e) => setSituationDeHandicap(e.target.value)}
                                    type="radio" id="customRadioInline7" name="customRadioInline7" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline7"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="5. Non"
                                    onChange={(e) => setSituationDeHandicap(e.target.value)}
                                    type="radio" id="customRadioInline8" name="customRadioInline7" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline8"> Non</label>
                            </div>
                        </div>
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">
                                5.b Si vous avez des besoins spécifiques (situation de handicap), pouvez-vous nous les présenter?
                            </h4>
                            <textarea
                                name="besoinsSpecifiquesHandicap"
                                onChange={(e) => handleInput(e)}
                                value={client.besoinsSpecifiquesHandicap}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>

                    </div>
                </div>

                <div className="col-md-6 offset-md-3">


                    <h3 htmlFor="exampleFormControlSelect1">6. Observations complémentaires</h3><br />
                    <div id="block" required >
                        <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">6.a. Avez-vous conscience que votre projet de formation nécessite, selon le profil de chaque candidat, entre 600 et 1100h de formation?</h4><br />
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="6. Oui"
                                onChange={(e) => setConscienceTempsNecessaire(e.target.value)}
                                type="radio" id="customRadioInline10" name="customRadioInline10" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline10"> Oui</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="6. Non"
                                onChange={(e) => setConscienceTempsNecessaire(e.target.value)}
                                type="radio" id="customRadioInline11" name="customRadioInline10" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline11"> Non</label>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 offset-md-3">

                    <div id="block" required >

                        <h4 htmlFor="exampleFormControlSelect1">6.b. Disposez-vous d'un support numérique (ordinateur, téléphone, tablette, ...) vous permettant de vous connecter à internet ?</h4><br />
                        <div className="custom-control custom-checkbox">
                            <input
                                value="6.b Ordinateur fixe de bureau"
                                onChange={(e) => supportNumerique.push(e.target.value)}
                                type="checkbox" className="custom-control-input" id="customCheck55" />
                            <label className="custom-control-label" htmlFor="customCheck55"> Ordinateur fixe de bureau</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                value="6.b Ordinateur portable"
                                onChange={(e) => supportNumerique.push(e.target.value)}
                                type="checkbox" className="custom-control-input" id="customCheck56" />
                            <label className="custom-control-label" htmlFor="customCheck56"> Ordinateur portable</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                value="6.b Tablette numérique"
                                onChange={(e) => supportNumerique.push(e.target.value)}
                                type="checkbox" className="custom-control-input" id="customCheck57" />
                            <label className="custom-control-label" htmlFor="customCheck57"> Tablette numérique</label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                value="6.b Téléphone portable personnel"
                                onChange={(e) => supportNumerique.push(e.target.value)}
                                type="checkbox" className="custom-control-input" id="customCheck58" />
                            <label className="custom-control-label" htmlFor="customCheck58"> Téléphone portable personnel </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                value="6.b Autre"
                                onChange={(e) => supportNumerique.push(e.target.value)}
                                type="checkbox" className="custom-control-input" id="customCheck59" />
                            <label className="custom-control-label" htmlFor="customCheck59"> Autre</label>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 offset-md-3">
                    <div div id="block">

                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">6.c. Votre avenir professionnel...Avez-vous besoin d’un accompagnement à la création d’activité par la suite ?</h4><br />
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="6. Oui"
                                onChange={(e) => setAccompagnement(e.target.value)}
                                type="radio" id="customRadioInline12" name="customRadioInline12" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline12"> Oui</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                value="6. Non"
                                onChange={(e) => setAccompagnement(e.target.value)}
                                type="radio" id="customRadioInline13" name="customRadioInline12" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadioInline13"> Non</label>
                        </div>
                        <div style={{ marginTop: "20px" }}>

                            <input
                                name="besoinsCreationActivite"
                                onChange={(e) => handleInput(e)}
                                value={client.besoinsCreationActivite}
                                placeholder='Si oui, merci de détailler'
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 offset-md-3">
                    <div div id="block">
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">6.d. Souhaitez-vous nous transmettre d'autres informations complémentaires ?</h4><br />
                        <input
                            name="autresInformationsComplementaires"
                            onChange={(e) => handleInput(e)}
                            value={client.autresInformationsComplementaires}
                            type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                    </div>
                </div>

                <div className="col-md-6 offset-md-3">
                    <h3 style={{ marginBottom: 0 }} htmlFor="exampleFormControlSelect1">7. Téléchargez vos fichiers <span>*</span></h3><br />

                    <div id="block" style={{ margin: "30px" }}>
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">CV</h4><br />

                        <div className="input-group mb-3">
                            {/* <div className="input-group-prepend">
                                    <span className="input-group-text">Télécharger</span>
                                </div> */}
                            <div className="custom-file">
                                {/* <input type="file" className="custom-file-input" id="inputGroupFile01" /> */}
                                <input type="file"
                                    name="cvMedia"
                                    required
                                    onChange={(e) => setCvMedia(e.target.files[0])}
                                    // value={formData.cvMedia}
                                    class="form-control" id="customFile" />
                                {/* <label className="custom-file-label" for="inputGroupFile01"></label> */}
                            </div>
                        </div>
                    </div>
                    <div id="block" style={{ margin: "30px" }}>
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Dernier diplôme</h4><br />

                        <div className="custom-file">

                            <input type="file"
                                name="diplomeMedia"
                                required
                                onChange={(e) => setDiplomeMedia(e.target.files[0])}
                                // value={formData.diplomeMedia}
                                class="form-control" id="customFile" />
                        </div>
                    </div>

                    <div id="block" style={{ margin: "30px" }}>
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Lettre de motivation</h4><br />

                        <div className="custom-file">

                            <input type="file"
                                name="lettreDeMotivationMedia"
                                required
                                onChange={(e) => setLettreDeMotivationMedia(e.target.files[0])}
                                // value={formData.lettreDeMotivationMedia}
                                class="form-control" id="customFile" />
                        </div>
                    </div>

                    <div id="block" style={{ margin: "30px" }}>
                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Pièce d'identité</h4><br />

                        <div className="custom-file">

                            <input type="file"
                                name="cniMedia"
                                required
                                onChange={(e) => setCniMedia(e.target.files[0])}
                                // value={formData.cniMedia}
                                class="form-control" id="customFile" />
                        </div>
                    </div>

                </div>

                <div className="col-md-6 offset-md-3" >
                    <h3 htmlFor="exampleFormControlSelect1">Signature <span>*</span></h3><br />

                    <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Merci de signer ce formulaire</h4><br />
                    <div id="block">

                        <div style={{ width: "100%", height: 250, border: "1px, solid, black" }}>

                            <SignatureCanvas
                                required
                                canvasProps={{ width: 350, height: 250, className: 'sigCanvas' }}
                                ref={data => setSign(data)}
                            />
                        </div>
                        {/*                             
                             <button style={{ margin: "30px", backgroundColor: "#94B9D9" }} className="btn btn-primary" onClick={handleClear}>Effacer</button> */}
                    </div>

                </div>
                <ClipLoader
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <div className="col-md-6 offset-md-3">

                    <button style={{ margin: "30px", marginTop: "60px", backgroundColor: "#94B9D9" }} className="btn btn-primary" onClick={(e) => {
                        handleSave(e);
                        setProgress(100)
                    }} type="submit">Régler les Frais de Formation</button>

                    {/* <button onClick={() => navigate('/payment')} style={{ margin: "30px", backgroundColor: "#94B9D9" }} className="btn btn-primary">
                            Régler les frais d'inscription
                        </button> */}
                    {/* <Payment /> */}
                    <div><h4>L'ISMI se réserve le droit de sélectionner des candidats.</h4></div>
                    <div><h4>Un remboursement sera effectué sous 72h ouvrés si votre dossier d'inscription n'est pas accepté par l'ISMI.</h4></div>
                    <div style={{ margin: "40px" }}>***********************************</div>
                </div>
            </form >
            {/* <img src={url} /> */}
            <div className="col-md-6 offset-md-3">
                <div>

                    <h4>Si vous souhaitez un rendez-vous téléphonique avec un membre de notre équipe, merci
                        de choisir un créneau ci-dessous.<br />
                        Veillez à être disponible sur le créneau que vous avez réservé.
                    </h4>
                </div>
                <div styles={{ marginBottom: "50px" }}><h4>Compte tenu du nombre limité de places, nous ne pouvons nous engager sur un second appel</h4></div>
                {/* <label className="col-sm-2 col-form-label">Appel Téléphonique</label> */}


                <InlineWidget url="https://calendly.com/contact-4378/rendez-vous-telephonique" />



            </div>
            <div style={{ margin: 30 }}><span>*</span> : Champs Obligatoires</div>
            <div style={{ margin: 30 }}>
                <ol>
                    <li>
                        <a href='/ConditionsGeneralesDUtilisation'>

                            Conditions Générales d'Utilisation
                        </a>
                    </li>
                    <li>
                        <a href='/politiquedeconfidentialite'>

                            Politique de Confidentialité
                        </a>
                    </li>
                </ol>
            </div>




        </div >
    )
}