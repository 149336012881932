import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import banniere from "../../images/PictureISMI.png"
import DashBoard from "../../pages/Dashboard"

import signature from "../../images/SignatureISMI.png"
import { Font } from '@react-pdf/renderer';

import myCustomFont from './fonts/calibrib.ttf'

Font.register({
    family: 'calibrib',
    src: myCustomFont
})
// Create styles
const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        paddingTop: "60",
        paddingLeft: "60",
        paddingRight: "60",
        // paddingBottom: 65,
        // paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 10,
        textAlign: "center",
        fontSize: "12pt"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12pt"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10pt",
        //spaddingBottom: "40px",
        marginTop: "30px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    },
    h1: {
        color: "black",
        fontStyle: "normal",
        fontWeight: "bold",
        textDecoration: "underline",
        fontSize: "14pt",
        margin: "15"
    },
});

// Create Document Component
export const AttestationDeSuiviEtDeFinDeFormation = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>

                <Image
                    //style={styles.image} 
                    src={banniere}
                />
            </View>
            <View style={styles.left}>

                <Text>N° d’enregistrement auprès du préfet IDF :</Text> <br />
                <Text>11 75 44 681 75</Text>
            </View>
            <View style={styles.right}>

                <Text>Paris, Le : {new Intl.DateTimeFormat('fr-fr').format(Date.now())},</Text>
            </View>
            <View style={{ margin: "50px" }}>

                <Text style={{ ...styles.h1, textAlign: "center", color: "red", fontSize: "14px" }}>ATTESTATION DE SUIVI ET DE FIN DE FORMATION</Text>
            </View>
            <View >
                <Text style={styles.text}>L’Institut de Stratégie et de Management International, représenté par Madame Audrey<br />
                    DUONG, responsable des formations, atteste, par la présente, que :<br />
                </Text>
                <Text style={{
                    ...styles.text, color: "black",
                    fontFamily: 'calibrib',
                    //fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "13pt",
                }}>{props.sex} {props.prenom} {props.nom}</Text>
                <Text style={styles.text}>est entré(e ) et a suivi l’équivalent de 1 100h de formation professionnelle continue en vue <br />
                    d’obtenir le diplôme certifié par l’Etat et enregistré au RNCP de {props.nomDeLaFormation}. <br />

                    La formation s’est déroulée sur l’année académique 2023 - 2024.<br />
                </Text>
                <Text style={styles.text}>Sous réserve de transmission des documents réglementaires exigés par le ministère <br />
                    et/ou le certificateur au mois d’octobre chaque année, la présente attestation permet à <br />
                    l’intéressé (e ) d’accéder à l’inscription en candidat libre pour le passage du diplôme précité <br />
                    pendant 4 années supplémentaires en sus de cette actuelle année académique.</Text>
            </View>
            <View style={{ ...styles.right, marginTop: "20px" }}>
                <Text>Pour faire valoir ce que de droit,</Text>
                <Text style={{ textIndent: "0pt", textAlign: "left" }}>

                </Text><br />

            </View>
            <View style={{ marginLeft: "130px", width: "40%", height: "20%", marginTop: "20px", marginBottom: "20px" }}>

                <Image
                    src={signature}
                />
            </View>
            <View style={{ ...styles.right, marginTop: '-100px' }}>
                <Text >Madame Audrey DUONG</Text> <br />
                <Text style={styles.right}>
                    Directrice.
                </Text>
            </View>
            <View
                style={styles.footer}
            >
                <Text>

                    © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                <Text>

                    Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                </Text><br />
                <Text>

                    Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                </Text><br />
                <Text>

                    Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                </Text><br />
                <Text>

                    Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                </Text><br />
                <Text>

                    Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                </Text>

            </View>
        </Page >
    </Document >
);